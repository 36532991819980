@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .border-1 {
    border-width: 1px;
  }

  .hd-screen {
    height: 100dvh;
  }

  .justify-safe-center {
    justify-content: safe center;
  }
}

html,
body,
#__next {
  height: 100dvh;
  background-color: #fcfcfc;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: black;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

input[type="date"] {
  background-color: transparent !important;
}

.firebase-emulator-warning {
  display: none;
}
